import { useEffect } from 'react';
import { Spin } from 'antd';

const Loading = props => {
    useEffect(() => {
        /*global.setGlobal('loading', !global.state.loading);
        console.log('loading');

        return () => {
            console.log('not loading')
            global.setGlobal('loading', !global.state.loading);
        }*/
    }, []);

    if (props.error) {
        return <div>Error! <button onClick={ props.retry }>Retry</button></div>;
    } else if (props.pastDelay) {
        return <Spin />;
    } else if (props.timedOut) {
        return <div>Taking a long time... <button onClick={ props.retry }>Retry</button></div>;
    } else {
        return <div className='ahmedrhuma-loadingcomponent'><Spin size='large' /></div>;
    }
}

export default Loading;