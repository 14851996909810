import { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import {
  Alert,
  Divider,
  Tooltip,
  Button,
  Row,
  Col,
  Image,
  Drawer,
  Skeleton,
  message,
} from "antd";
import NodeJS from "../icons/nodejs.svg";
import Php from "../icons/php.svg";
import ReactJS from "../icons/react.svg";
import MongoDB from "../icons/mongodb.svg";
import MySQL from "../icons/mysql.svg";
import Electron from "../icons/electron.svg";
import jQuery from "../icons/jquery.svg";
import Joomla from "../icons/joomla.svg";
import Html from "../icons/html.svg";
import Wordpress from "../icons/wordpress.svg";
import MQL4 from "../icons/metatrader.svg";
import Java from "../icons/java.svg";
import Css from "../icons/css.svg";
import { Get, BASE } from "../request.js";

import "./singlejob.scss";

const SingleJob = ({ job, onClose, ...rest }) => {
  const [preview, setPreview] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");
  const { row, visible } = job || {};

  useEffect(() => {
    if (row && row._id) {
      Get(`portfolio-backend/${row.type}/${row._id}`, {}, false)
        .then((res) => {
          res.text().then((d) => {
            setData(d);
            setLoading(false);
          });
        })
        .catch(() => {
          message.error(
            "Unable to fetch portfolio item, please try again later."
          );
        });
    }
  }, [row]);

  if (row === null || (row && !row._id)) return null;

  const getImage = (i) => {
    switch (i) {
      case "react":
        return ReactJS;
      case "php":
        return Php;
      case "nodejs":
        return NodeJS;
      case "mongodb":
        return MongoDB;
      case "electron":
        return Electron;
      case "mysql":
        return MySQL;
      case "jquery":
        return jQuery;
      case "joomla":
        return Joomla;
      case "html":
        return Html;
      case "css":
        return Css;
      case "mql4":
        return MQL4;
      case "java":
        return Java;
      case "wordpress":
        return Wordpress;
      default:
        return null;
    }
  };

  const generateIcons = (item) => {
    const total =
      item.frontend.length + item.database.length + item.backend.length;
    const Span = Math.ceil(24 / total);
    const ret = [];
    ret.push(
      <Divider key={`${item.id}_ft`}>Frontend, Database & Backend</Divider>
    );
    ret.push(
      <Row key={`${item.url}_f`} gutter={16}>
        {item.frontend.map((i) => (
          <Col key={"fe" + i} span={Span}>
            <Tooltip
              title={i.charAt(0).toUpperCase() + i.slice(1)}
              color="pink"
            >
              <img alt={i} src={getImage(i)} />
            </Tooltip>
          </Col>
        ))}
        {item.database.map((i) => (
          <Col key={"db" + i} span={Span}>
            <Tooltip
              title={i.charAt(0).toUpperCase() + i.slice(1)}
              color="pink"
            >
              <img alt={i} src={getImage(i)} />
            </Tooltip>
          </Col>
        ))}
        {item.backend.map((i) => (
          <Col key={"be" + i} span={Span}>
            <Tooltip
              title={i.charAt(0).toUpperCase() + i.slice(1)}
              color="pink"
            >
              <img alt={i} src={getImage(i)} />
            </Tooltip>
          </Col>
        ))}
      </Row>
    );
    return ret;
  };

  return (
    <Drawer
      title={row.name}
      placement="right"
      width="100%"
      closable
      onClose={onClose}
      visible={visible}
      className="ahmedrhuma-drawer-contents"
    >
      <Skeleton loading={loading} active>
        <Row gutter={16}>
          <Col span={12}>
            <ReactMarkdown
              children={data}
              components={{
                a({ node, inline, className, children, ...props }) {
                  if (
                    children &&
                    children.length === 1 &&
                    children[0].props &&
                    children[0].props.children &&
                    children[0].props.children.length > 0
                  ) {
                    if (children[0].props.children[0] === "Private") {
                      return (
                        <Alert
                          message="No Preview"
                          description="Due to the copyrights and the confidentiality of this project, I'm not allowed to show preview for this project."
                          type="warning"
                          showIcon
                          key="alert"
                        />
                      );
                    } else if (children[0].props.children[0] === "NOPREVIEW") {
                      return (
                        <Alert
                          message="No Preview"
                          description="Unfortunately, this project is outdated and there is no preview available."
                          type="warning"
                          showIcon
                          return="nopreviewalert"
                        />
                      );
                    } else if (
                      String(children[0].props.children[0]).startsWith("HUEMIX")
                    ) {
                      return (
                        <a target="_blank" {...props}>
                          {children[0].props.children[0].substring(6)}
                        </a>
                      );
                    }
                  }
                  return (
                    <Button
                      target="_blank"
                      type="primary"
                      className={className}
                      {...props}
                    >
                      {children}
                    </Button>
                  );
                },
              }}
            />
          </Col>
          <Col span={12}>
            <h1>Project Gallery</h1>
            <Image
              className="ahmedrhuma-singlepreview"
              preview={{ visible: false }}
              width="100%"
              src={
                BASE +
                "img/" +
                String(row._id) +
                "/" +
                ((row.images && row.images.length > 0 && row.images[0]) ||
                  "default.jpg")
              }
              onClick={() => setPreview(true)}
            />
            <div style={{ display: "none" }}>
              <Image.PreviewGroup
                preview={{
                  visible: preview,
                  onVisibleChange: (vis) => setPreview(vis),
                }}
              >
                {row.images.map((i) => (
                  <Image
                    key={"subimg" + i}
                    src={BASE + "img/" + String(row._id) + "/" + String(i)}
                    alt={row.name}
                  />
                ))}
              </Image.PreviewGroup>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="icons-container">{generateIcons(row)}</div>
        </Row>
      </Skeleton>
    </Drawer>
  );
};

export default SingleJob;
