import { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { Result, message, Form, Input, Button, Col, Row, Spin } from 'antd';
import { SendOutlined, SmileOutlined, MailOutlined } from '@ant-design/icons';
import ReCAPTCHA from "react-google-recaptcha";
import { Post } from '../request.js';

import './contact.scss';

const wrapperCol = {
    lg: {
        span: 21
    }
}

const Contact = () => {
    const [cap, setCap] = useState(null);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [result, setResult] = useState(false);

    useEffect(() => {
        if(result){
            setTimeout(() => {
                //setResult(false);
            }, 5000);
        }
    }, [result]);

    const styles = useSpring({
        to: { opacity: 1, x: 0 },
        from: { opacity: 0, x:-100 },
    });

    const formstyles = useSpring({
        to: { opacity: 1, y: 0 },
        from: { opacity: 0, y:-1000 },
    });

    const onFinish = values => {
        if(!cap){
            message.warn('Please fill the Captcha');
            return;
        }
        setLoading(true);
        Post('contactme', {...values, captcha: cap}).then(res => {
            form.resetFields();
            setResult(true);
            setLoading(false);
        }).catch(e => {
            setLoading(false);
            message.error('An error while sending the form, please try again later.');
        })
    }

    const onChange = v => setCap(v);

    return (<div className="ahmedrhuma-contact">
        <animated.h1 style={styles}>GET IN <span>TOUCH</span></animated.h1>
        <animated.span className="title-bg" style={styles}>CONTACT</animated.span>
        <animated.div style={formstyles} className="ahmedrhuma-contactform">
            <Row>
                <Col className="ahmedrhuma-leftarea" sm={24} xs={24} md={8} lg={8} xl={8} xxl={8}>
                    <h2>DON'T BE SHY !</h2>
                    <p>Feel free to get in touch with me. I am always open to discussing new projects, creative ideas or opportunities to be part of your visions.</p>
                    <Row>
                        <Col className="ahmedrhuma-emailicon" span={4}><MailOutlined /></Col>
                        <Col className="ahmedrhuma-email" span={20}>ahmed@rhuma.net</Col>
                    </Row>
                </Col>
                <Col sm={24} xs={24} md={16} lg={16} xl={16} xxl={16}>
                    <p className="ahmedrhuma-formnote">If you want faster response you may contact me at Facebook, otherwise you can use this form to email me directly</p>
                    {result? <Result
                        className="ahmedrhuma-contactresult"
                        icon={<SmileOutlined />}
                        title="Great, your email has been arrived, I'll get back to you soon."
                    /> : 
                    <Spin spinning={loading}>
                        <Form form={form} labelAlign='left' wrapperCol={wrapperCol} name="contact" onFinish={onFinish}>
                            <Row gutter={16}>
                                <Col md={8} xs={12}>
                                    <Form.Item name='name' rules={[{ required: true }]}>
                                        <Input placeholder="YOUR NAME" />
                                    </Form.Item>
                                </Col>
                                <Col md={8} xs={12}>
                                    <Form.Item name='email'rules={[{ type: 'email', required: true }]}>
                                        <Input placeholder="YOUR EMAIL" />
                                    </Form.Item>    
                                </Col>
                                <Col  md={8} xs={24}>
                                    <Form.Item name='subject'>
                                        <Input placeholder="YOUR SUBJECT" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name='message' rules={[{ required: true }]}>
                                        <Input.TextArea placeholder="YOUR MESSAGE" allowClear rows={4}  showCount />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item name="captcha" colon={false} label=' '>
                                <ReCAPTCHA
                                    theme='dark'
                                    sitekey="6LdP01IcAAAAAB8Tqf7F5UINeZHhDz57fMCUZhr-"
                                    onChange={onChange}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button icon={<SendOutlined />} type="primary" htmlType="submit">
                                    Send Message
                                </Button>
                            </Form.Item>
                        </Form>
                    </Spin>}
                </Col>
            </Row>
        </animated.div>
    </div>)
}

export default Contact;