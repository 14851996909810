import { useState, useEffect } from "react";
import {
  Empty,
  Tooltip,
  Divider,
  Badge,
  Row,
  Col,
  Card,
  Skeleton,
  Tabs,
  message,
} from "antd";
import {
  CodeSandboxOutlined,
  CodeOutlined,
  LineChartOutlined,
  DesktopOutlined,
} from "@ant-design/icons";
import SingleJob from "./SingleJob";
import NodeJS from "../icons/nodejs.svg";
import Php from "../icons/php.svg";
import ReactJS from "../icons/react.svg";
import MongoDB from "../icons/mongodb.svg";
import MySQL from "../icons/mysql.svg";
import Electron from "../icons/electron.svg";
import jQuery from "../icons/jquery.svg";
import Joomla from "../icons/joomla.svg";
import Html from "../icons/html.svg";
import Java from "../icons/java.svg";
import Wordpress from "../icons/wordpress.svg";
import MQL4 from "../icons/metatrader.svg";
import Css from "../icons/css.svg";

import { Get, BASE } from "../request.js";

import "./portfolio.scss";

const { TabPane } = Tabs;
const { Meta } = Card;

let defaultTab = "app";
let defaultWaiting = "";
const items = String(window.location.search).split("&");
items.map((item) => {
  const data = String(item).split("=");
  if (data[0] === "?section") defaultTab = data[1];
  else if (data[0] === "project") defaultWaiting = data[1];
  return item;
});

const Portfolio = () => {
  const [activeTab, setTab] = useState(defaultTab);
  const [loading, setLoading] = useState(true);
  const [single, setSingle] = useState({ visible: false, row: null });
  const [data, setData] = useState([]);
  const [waiting, setWaiting] = useState(defaultWaiting);

  useEffect(() => {
    const link = "/portfolio?section=" + activeTab;
    window.history.replaceState(null, "", link);
    load();
  }, [activeTab]);

  useEffect(() => {
    if (single.row == null) {
      const link = "/portfolio?section=" + activeTab;
      window.history.replaceState(null, "", link);
      return;
    }
    const link =
      "/portfolio?section=" + activeTab + "&" + "project=" + single.row.url;
    window.history.replaceState(null, "", link);
  }, [single]);

  const load = () => {
    setLoading(true);
    Get("portfolio-backend", { category: activeTab })
      .then((res) => {
        setLoading(false);
        setData(res);
        if (waiting !== "") {
          // set the waiting project
          for (let i = 0; i < res.length; i++) {
            const item = res[i];
            if (item.url === waiting) {
              setSingle({
                visible: true,
                row: item,
              });
              return;
            }
          }
        }
      })
      .catch(() => {
        setLoading(false);
        setData([]);
        message.error("Unable to fetch portfolio, please try again later.");
      });
  };

  const tabs = [
    {
      id: "app",
      name: "Web Applications",
      icon: <CodeOutlined />,
    },
    {
      id: "web",
      name: "Website",
      icon: <DesktopOutlined />,
    },
    {
      id: "mt",
      name: "Metatrader Development",
      icon: <LineChartOutlined />,
    },
    {
      id: "scripts",
      name: "Scripts",
      icon: <CodeSandboxOutlined />,
    },
  ];

  const changeTab = (tab) => {
    setTab(tab);
  };

  const SetSingleItemView = (row) => {
    setSingle({ visible: true, row });
  };

  const onClose = () => {
    setSingle({ visible: false, row: null });
  };

  const getImage = (i) => {
    switch (i) {
      case "react":
        return ReactJS;
      case "php":
        return Php;
      case "nodejs":
        return NodeJS;
      case "mongodb":
        return MongoDB;
      case "electron":
        return Electron;
      case "mysql":
        return MySQL;
      case "jquery":
        return jQuery;
      case "joomla":
        return Joomla;
      case "html":
        return Html;
      case "css":
        return Css;
      case "mql4":
        return MQL4;
      case "java":
        return Java;
      case "wordpress":
        return Wordpress;
      default:
        return null;
    }
  };

  const generateIcons = (item) => {
    let total =
      item.frontend.length + item.backend.length + item.database.length;
    const icons = [...item.frontend, ...item.backend, ...item.database];
    const Span = Math.ceil(24 / total);
    return (
      <Row gutter={16}>
        {icons.map((i) => (
          <Col key={i} span={Span}>
            <Tooltip
              title={i.charAt(0).toUpperCase() + i.slice(1)}
              color="volcano"
            >
              <img alt={i} src={getImage(i)} />
            </Tooltip>
          </Col>
        ))}
      </Row>
    );
  };

  const renderData = () => {
    if (loading) return null;
    if (data.length === 0) return <Empty />;
    return data.slice(0).map((item) => {
      return (
        <Col
          className="item-col"
          key={item._id}
          xxl={8}
          xl={8}
          lg={8}
          md={12}
          sm={24}
          xs={24}
        >
          <Badge.Ribbon text={item.year} color="volcano">
            <Card
              size="small"
              hoverable
              onClick={SetSingleItemView.bind(null, item)}
              cover={
                <img
                  alt={item.name}
                  src={
                    BASE +
                    "img/" +
                    String(item._id) +
                    "/" +
                    ((item.images &&
                      item.images.length > 0 &&
                      item.images[0]) ||
                      "default.jpg")
                  }
                />
              }
            >
              <Meta title={item.name} description={item.description} />
              <Divider>Used in this project</Divider>
              <div className="icons-container">{generateIcons(item)}</div>
            </Card>
          </Badge.Ribbon>
        </Col>
      );
    });
  };

  return (
    <div className="ahmedrhuma-portfolio">
      <h1>My work</h1>
      <SingleJob onClose={onClose} job={single} />
      <div className="ahmedrhuma-portfolio-note">
        Due to confidentiality and copyrights agreements, I am not allowed to
        share some of my projects.
      </div>
      <div className="ahmedrhuma-tabs-content">
        <Tabs
          activeKey={activeTab}
          onChange={changeTab}
          animated
          centered
          size="large"
          defaultActiveKey="1"
        >
          {tabs.map((item) => (
            <TabPane
              tab={
                <span>
                  {item.icon}
                  {item.name}
                </span>
              }
              key={item.id}
            >
              <Skeleton active loading={loading}>
                <Row gutter={16}>{renderData()}</Row>
              </Skeleton>
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default Portfolio;
