import { useState, useEffect } from 'react';
import Background from './components/BackgroundEffects';
import Menu from './components/Menu';
import Footer from './components/Footer';
//import MouseEffect from './components/MouseEffect';
import MainPage from './pages/Main';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import Loading from './components/Loading';

import './App.css';

const Title="AhmedRhuma";

const mainState = {
  page: 'main',
  pageon: true,
  waiting: false,
  requested: ''
};

if(window.location.pathname==='/portfolio') mainState.page='portfolio';
else if(window.location.pathname==='/contact') mainState.page='contact';

function App() {
  const [state, setState] = useState(mainState);

  useEffect(() => {
    window.addEventListener('popstate', function(event) {
      if(event.target.location){
        if(event.target.location.pathname==='/') changePage('/', event);
        else if(event.target.location.pathname==='/portfolio') changePage('portfolio', event);
        else if(event.target.location.pathname==='/contact') changePage('contact', event);
      }
    }, false);

    if(state.page==='portfolio') document.title=Title+' - Portfolio';
    else if(state.page==='contact') document.title=Title+' - Contact';
  }, []);

  const changePage = (newPage, e) => {
    e.preventDefault();
    setState({...state, page: '', requested: newPage, waiting: true, pageon: false, timeout: 0 });
  }

  const onFinish = (hasClass) => {
    if(state.waiting){
      if(!hasClass) {
        setState({...state, pageon: true });
        if(state.requested==='main'){
          document.title=Title;
          window.history.pushState(null, '', '/');
        }
      }
      else {
        setState({...state, page: state.requested, requested: '', waiting: false});
        if(state.requested!=='main'&&'/'+state.requested!==window.location.pathname){
          window.history.pushState(null, '', '/'+state.requested);
        }
        document.title=Title+' - '+state.requested.charAt(0).toUpperCase() + state.requested.slice(1);
      }
    }
  }

  return (
    <div className={`page-${state.page}`}>
      <div className="ahmedrhuma-content">
        <Menu changePage={changePage} active={state.page} />
        <div className="ahmedrhuma-pages">
        {state.page==='main'?<MainPage timeout={state.timeout} />:null}
        {state.page==='portfolio'?<Portfolio />:null}
        {state.page==='contact'?<Contact />:null}
        {state.page===''?<Loading />:null}
        </div>
      </div>
      <Footer page={state.page} changePage={changePage} />
      <Background onFinish={onFinish} activePage={state.page} video={state.page==='main'} isActive={state.pageon} />
    </div>
  );
}

export default App;
