import { useEffect, useState } from 'react';
import VideoBG from './VideoBG.js';
import './backgroundEffects.scss';

const BackgroundEffects = ({ onFinish, activePage, isActive, video }) => {
    const [className, setClass] = useState('');

    useEffect(() => {
        if(isActive) setClass('finish');
        else setClass('');
        if(typeof onFinish === 'function'){
            setTimeout(() => {
                onFinish(isActive?'finish':'');
            }, 1000);
        }
    }, [isActive]);

    useEffect(() => {
        setTimeout(() => {
            setClass('finish');
        }, 2000);
    }, []);

    return (
        <>
        {video?<VideoBG />:null}
        <div className={`ahmedrhuma-custombg ${className} ${activePage==="main"?"":"ready"}`}>
            <div className="ahmedrhuma-bgcontent">
                <div className="ahmedrhuma-lines" />
                <div className="ahmedrhuma-lines" />
                <div className="ahmedrhuma-lines" />
                <div className="ahmedrhuma-lines" />
                <div className="ahmedrhuma-lines" />
            </div>
        </div>
        </>
    )
}

export default BackgroundEffects;