import { useSpring, animated } from '@react-spring/web';
import { useState, useEffect } from 'react';
import { Drawer, Button } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import AboutME from './AboutME.js';
import TypeWriter from '../components/Typewriter.js';

import './main.scss';

const Main = ({ timeout=3500 }) => {
    const [show, setShow]=useState(false);
    const [visible, setVisible]=useState(false);

    useEffect(()=>{
        setTimeout(() => setShow(true), timeout);
    }, []);

    const ShowResume = () => setVisible(true);

    const styles = useSpring({
        to: { opacity: show?1:0, x: show?0:-50 },
        from: { opacity: 0, x:-50 },
    });

    const onClose = () => setVisible(false);

    return (
        <div className="ahmedrhuma-main-title-container">
            <animated.h1 style={styles} className="maintitle">
            Hello, I'm <strong>Ahmed Rhuma</strong>, Full stack Developer.
            </animated.h1>
            <TypeWriter delayStart={show?100:timeout} del={24} wait={1000}>
                I code with <strong>React</strong> and <strong>NodeJS</strong>*
                for Metatrader with <strong>MQL4,5</strong>
            </TypeWriter>
            <div className="ahmedrhuma-showmore-container">
                <Button onClick={ShowResume} icon={<CaretRightOutlined />} className="ahmedrhuma-showmore">More About ME</Button>
            </div>
            <Drawer
                title='MY RESUME'
                placement="right"
                size='large'
                width="90%"
                onClose={onClose}
                visible={visible}
            >
                <AboutME />
            </Drawer>
        </div>
    );
}

export default Main;