import { useSpring, animated } from '@react-spring/web';
import { useState, useEffect } from 'react';
import { FacebookOutlined, ArrowDownOutlined } from '@ant-design/icons';
import './footer.scss';

const Footer = ({ page, changePage }) => {
    const [show, setShow]=useState(false);

    const styles = useSpring({
        to: { opacity: show?1:0, y: show?0:300 },
        from: { opacity: 0, y:300 },
    });

    const arrow = useSpring({
        loop: { reverse: true },
        config: { mass: 10, tension: 380, friction: 30 },
        to:  { opacity: 0.8, y: 20 },
        from: { opacity: 0.3, y: 0 },
      })

    useEffect(() => {
        setTimeout(() => setShow(true), 1500);
    }, []);

    return (
        <div className="ahmedrhuma-footer">
            {page==='main' ? <animated.div style={arrow} className="footer-arrow">
                <ArrowDownOutlined onClick={changePage.bind(null, 'portfolio')} />
            </animated.div>:null}
            <animated.div style={styles} className="social">
                <div className="follow">
                    Follow Me
                </div>
                <div>
                    <a rel="noreferrer" target="_blank" href="https://fb.com/ahmed.rhuma"><FacebookOutlined /></a>
                </div>
            </animated.div>
        </div>
    )
}

export default Footer;