import { Row, Col, Tooltip } from 'antd';
import NodeJS from '../icons/nodejs.svg';
import Php from '../icons/php.svg';
import ReactJS from '../icons/react.svg';
import MongoDB from '../icons/mongodb.svg';
import MySQL from '../icons/mysql.svg';
import Electron from '../icons/electron.svg';
import jQuery from '../icons/jquery.svg';
import MQL from '../icons/metatrader.svg';

import './aboutme.scss';

const AboutME = () => {

    const getImage = i => {
        switch(i){
            case 'react': return ReactJS;
            case 'php': return Php;
            case 'nodejs': return NodeJS;
            case 'mongodb': return MongoDB;
            case 'electron': return Electron;
            case 'mysql': return MySQL;
            case 'jquery': return jQuery;
            case 'mql': return MQL;
            default: return null; 
        }
    }


    const generateIcons = () => {
        const icons=['react','nodejs','php', 'jquery', 'electron', 'mongodb', 'mysql', 'mql']
        const Span = Math.ceil(24/16);
        return <Row justify="center" gutter={16}>
            {icons.map(i => <Col key={i} md={Span+1} lg={Span} xl={Span} xxl={Span} sm={Span+1} xs={Span+4}><Tooltip title={i.charAt(0).toUpperCase()+i.slice(1)} color="yellow"><img alt={i} src={getImage(i)} /></Tooltip></Col>)}
        </Row>;
    }

    return (
        <Row style={{ position: 'relative', textAlign: 'center' }} gutter={16} className="ahmedrhuma-resume-container">
            <h1>ABOUT <span>ME</span></h1>
            <span className='title-bg'>RESUME</span>
            <Col className="resume-rows" md={24} lg={12} xl={12} xxl={12} sm={24} xs={24} style={{ textAlign: 'left' }}>
                <h2>PERSONAL INFO</h2>
                <Row gutter={16}>
                    <Col span={12}>
                        <p><span>First Name: </span> Ahmed</p>
                        <p><span>Last Name: </span>Rhuma</p>
                        <p><span>Age: </span>27 Years</p>
                        <p><span>Nationality: </span>Libyan</p>
                    </Col>
                    <Col span={12}>
                        <p><span>Address: </span> Istanbul, Turkey</p>
                        <p><span>Email: </span>ahmed@rhuma.net</p>
                        <p><span>Languages: </span>Arabic, English, Turkish (A2)</p>
                    </Col>
                </Row>
            </Col>
            <Col md={24} lg={12} xl={12} xxl={12} sm={24} xs={24} className="blocks-resume">
                <Row gutter={16}>
                    <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
                        <div>
                            <h3>10</h3>
                            <p>
                            Years of <span>Experience</span>
                            </p>
                        </div>
                    </Col>
                    <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
                        <div>
                            <h3>40</h3>
                            <p>
                            Completed <span>Projects</span>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col className='icons-container' span={24}>
                <h3>I WORK WITH</h3>
                {generateIcons()}
            </Col>
        </Row>
    )
}

export default AboutME;