import { useEffect, useState, Children, cloneElement } from 'react';
import './typewriter.scss';

const TypeWriter = props => {
    const { delayStart, children, del, wait, speed=120 } = props;
    const [data, setData]=useState([]);
    const items = Children.toArray(children);

    const untypeWithCallback = async (innerdata, count, i) => {
        if(count===0) {
            type(innerdata, aftertype, i);
            return;
        }
        const newdata = [...innerdata];
        const item=newdata.pop();
        if(typeof item === typeof {}){
            const itemchildren = item.props.children;
            const isundd=itemchildren.pop();
            if(isundd!==undefined){
                newdata.push(cloneElement(item, item.props, itemchildren));
            }
        }
        setData(newdata);
        setTimeout(() => untypeWithCallback(newdata, count-1, i), speed);
    }

    const untype = async (innerdata, count, ondone) => {
        if(count===0) return;
        const newdata = [...innerdata];
        const item=newdata.pop();
        if(typeof item === typeof {}){
            const itemchildren = item.props.children;
            const isundd=itemchildren.pop();
            if(isundd!==undefined){
                newdata.push(cloneElement(item, item.props, itemchildren));
            }
        }
        if(item===undefined){
            if(typeof ondone === 'function') ondone();
            return;
        }
        setData(newdata);
        setTimeout(() => untype(newdata, count-1, ondone), 120);
    }

    const type = async (rawData=[], afterend, ix=0) => {
        const innerdata=rawData;
        for(let j=ix; j<items.length; j++){
            let item = items[j];
            if((typeof item === 'string' &&item[0]==="*")&&ix===j){
                item=item.slice(1);
            }
            if(item==="*" || (typeof item === 'string' &&item[0]==="*")){
                let ixret=j+1;
                if ((typeof item === 'string' &&item[0]==="*")){
                    ixret=j;
                }
                return afterTypeWithCallBack(innerdata, ixret); 
            }
            await new Promise(async (resolve, reject) => {
                if(typeof item === typeof {}) {
                    const str = item.props.children.trim().split("");
                    innerdata.push(" ");
                    for(let i=0; i<str.length; i++){
                        await new Promise((res, reject) => {
                            setTimeout(() => {
                                if(i>0) innerdata.pop();
                                innerdata.push(cloneElement(item, item.props, str.slice(0, i+1)));
                                if(i===str.length-1) innerdata.push(" ");
                                res(setData([...innerdata]));
                                if(i===str.length-1) resolve();
                            }, speed);
                        })
                    }
                }
                else {
                    const str=item.trim().split("");
                    for(let i=0; i<str.length; i++){
                        await new Promise((res, reject) => {
                            setTimeout(() => {
                                innerdata.push(str[i]);
                                res(setData([...innerdata]));
                                if(i===str.length-1) resolve();
                            }, speed);
                        })
                    }
                }
            });
            if(j===items.length-1 && typeof afterend === 'function') afterend(innerdata); 
        }
    }

    const afterTypeWithCallBack = (innerdata, i) => {
        if(del > 0 ) setTimeout(() => untypeWithCallback(innerdata, del, i), wait||250);
    }

    const repeat = () => {
        type([], aftertype);
    }

    const aftertype = (innerdata) => {
        setTimeout(() => untype(innerdata, 100, repeat), 2000);
    }

    useEffect(() => {
        setTimeout(repeat, delayStart||0);
    }, []);

    return (
        <p className="ahmedrhuma-typer">{data}{data.length>0?<span className="cursor">|</span>:null}</p>
    );
};

export default TypeWriter;