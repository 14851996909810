import "whatwg-fetch";

export const BASE = "/";

export const Post = async (url = "", data = {}) => {
  // Default options are marked with *
  const response = await fetch(BASE + url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    //credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  if (response.status !== 200) throw new Error(response.json());
  else return response.json(); // parses JSON response into native JavaScript objects
};

const makeQS = (params) => {
  return Object.keys(params)
    .map((item) => `${item}=${params[item]}`)
    .join("&");
};

export const Get = async (url = "", params = {}, josn = true) => {
  let extra = "";
  let p = makeQS(params);
  if (String(p).length > 0) extra = "?" + p;
  // Default options are marked with *
  const response = await fetch(BASE + url + extra, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    //credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  if (response.status !== 200) throw new Error(response.json());
  else {
    if (josn) return response.json();
    // parses JSON response into native JavaScript objects
    else return response;
  }
};
