import './videoBG.scss';

const VideoBG = () => {

    return (
        <div className="ahmedrhuma-vbg">
            <div className="ahmedrhuma-vbg-mask" />
            <div className="ahmedrhuma-vbg-texture" />
        </div>
    )
}

export default VideoBG;