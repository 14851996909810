import { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { useTrail, animated, useSpring } from '@react-spring/web';
import { MenuOutlined, UserOutlined, PhoneOutlined, AppstoreOutlined } from '@ant-design/icons';
import './menu.scss';

const Menu = props => {
    const { changePage, active } = props;
    const [show, setShow]=useState(false);

    useEffect(() => {
        setTimeout(() => setShow(true), 2000);
    }, []);
    

    const items = [
        {
            id: 'main',
            name: 'Main Page',
            icon: <UserOutlined />
        },
        {
            id: 'portfolio',
            name: 'Portfolio',
            icon: <AppstoreOutlined />
        },
        {
            id: 'contact',
            name: 'Contact Me',
            icon: <PhoneOutlined />
        }
    ];

    const trail = useTrail(items.length, {
        config: { mass: 7, tension: 350, friction: 30 },
        from: { opacity: 0, x: -20 },
        opacity: show?1:0,
        x: show?0:-20
      });

    const styles = useSpring({
        to: { opacity: show?1:0, x: show?0:-50 },
        from: { opacity: 0, x:-50 },
    });

    const getColor = () => {
        switch(active) {
            case 'main': return 'yellow';
            case 'portfolio': return 'green';
            case 'contact': return 'blue';
            default: return 'lime';
        }
    }

    return (
        <div className="ahmedrhuma-menu">
            <animated.div style={styles}><MenuOutlined className="main-icon" /></animated.div>
            <div className="ahmedrhruma-nav">
                <nav>
                    {trail.map(({ height, ...style }, index) => (
                    <animated.div key={index} style={style}>
                      <Tooltip color={items[index].id===active?getColor():""} title={items[index].name} key={index}>
                          <a className={items[index].id===active?"active":""} href="#" onClick={changePage.bind(null, items[index].id)} style={{ height }}>{items[index].icon}</a>
                      </Tooltip>
                    </animated.div>
                  ))}
                </nav>
            </div>
        </div>
    )
}

export default Menu;